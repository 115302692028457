import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./css/Styles.css";


// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";


import img1 from './images/1.JPG';
import img2 from './images/2.JPG';
import img3 from './images/3.JPG';
import img4 from './images/4.JPG';
import img5 from './images/8.png';
import img6 from './images/6.JPG';

const Slide = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
           
        },
        {
            id: 2,
            imgSrc: img2,
         
        },
        {
            id: 3,
            imgSrc: img3,
         
        },
        {
            id: 4,
            imgSrc: img4,
           
        },
        {
            id: 5,
            imgSrc: img5,
        
        },
        {
            id: 6,
            imgSrc: img6,
      
        },
       
    ]
    return (
        <>

            <div className="slider mb-4 pb-4">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={true}
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {data.map((item, index) => {
                        return (<>
                       

                            <SwiperSlide>
                                

                                    <img src={item.imgSrc} />
                               
                                    
                              

                               

                            </SwiperSlide>


                    </>
                        )
                    })}

                </Swiper></div>
        </>
    );
}

export default Slide;