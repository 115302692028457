import restaurant from "./images/grand-.jpg";
import img_card from "./images/13.png";
import imgheaderrestaurant from "./images/14.jpg";
import imgheader from "./images/img6.jpg";
import imgheaderm from "./images/img6.jpg";
import img2 from "./images/3.JPG";
import imgheadermiini from "./images/img4.jpg";
import imgheadermiinimb from "./images/img4.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Costel",
        mireasa: "Maria",
        data: "27 August 2023",
        data_confirmare: "1 august 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "mariadalv@gmail.com", 
       tel: "+447423557397",
       phone: "tel:+447423557397",
       viber: "viber://chat?number=%2B447423557397",
       whatsapp: "https://wa.me/+447423557397",
       messenger: "https://www.messenger.com/t/maria.turcan.79",
       tel1: "+37368355031",
       phone1: "tel:+XXX",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+XXX",
       messenger1: "https://www.messenger.com/t/100008466997487",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Grand Elysée",
            data: "27 august 2023, duminică, ora 18:00",
            adress: "Strada Chișinăului 45, Sîngera",
            harta: "https://goo.gl/maps/qWtVnWwMRCjYDTmf8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10899.29043056449!2d29.0053241!3d46.9258694!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8d6e300c210a912e!2sGrand%20Elys%C3%A9e!5e0!3m2!1sro!2s!4v1672134684780!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care vom celebra căsătoria nostră, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;